<template>
  <div id="app">

    <!-- 页面内容 -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if='!$route.meta.keepAlive'></router-view>

    <!-- 底部导航栏 -->
    <navigation-bar></navigation-bar>
  </div>
</template>

<script>
import NavigationBar from '@/components/NavigationBar'

export default {
  name: 'App',

  components: { NavigationBar },

  beforeCreate () {
    const images = [
      require('@/assets/images/avatar.png'),
      require('@/assets/images/img_fail.png')
    ]
    for (const src of images) {
      const image = new Image()
      image.src = src
    }
  }
}

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
