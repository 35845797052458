import { get, post, put, del } from './http'

const api = {
  // 获取所有活动
  getAllActivity: (params = {}) => get(`/activities?currentPage=${params.currentPage || 1}&pageSize=${params.pageSize || 10}&keyword=${params.keyword || ''}`),
  // 获取单个活动
  getActivityByID: (params = {}) => get(`/activities/${params.id}`),
  // 报名活动
  signupActivity: (params = {}) => post(`/activities/${params.id}/signups`, params),
  // 活动签到
  signinActivity: (params = {}) => put(`/activity-signups/${params.id}`, params),
  // 取消报名
  cancelSignupActivity: (params = {}) => del(`/activities/${params.id}/signups`),
  // 获取用户自己的评价
  getActivityEvaluation: (params = {}) => get(`/activities/${params.id}/evaluation`),
  // 添加评价
  addActivityEvaluation: (params = {}) => post(`/activities/${params.id}/evaluations`, params),
  // 获取单个活动的所有评价
  getAllActivityEvaluationByActivityID: (params = {}) => get(`/activities/${params.id}/evaluations?currentPage=${params.currentPage || 1}&pageSize=${params.pageSize || 10}`),

  // 获取所有活动报名记录
  getAllActivitySignup: (params = {}) => get(`/activity-signups?currentPage=${params.currentPage || 1}&pageSize=${params.pageSize || 10}&keyword=${params.keyword || ''}&filter[status]=${params.status}`),
  // 获取单条活动报名记录
  getActivitySignup: (params = {}) => get(`/activity-signups/${params.id}`),
  // 获取某个活动用户自己的报名记录
  getAllActivitySignupForUser: (params = {}) => get(`/user/activities/${params.id}/activity-signups`),

  // 登录
  login: (params = {}) => post('/wechat/login', params),
  // 更新access_token
  getAccessToken: (params = {}) => post('/access-token', params),

  // 添加会员交流信息
  addMemberExchangeInfo: (params = {}) => post('/member-info-exchanges', params),
  // 获取会员交流信息
  getAllMemberExchangeInfo: (params = {}) => get(`/member-info-exchanges?filter[category]=${params.category || 'all'}&currentPage=${params.currentPage || 1}&pageSize=${params.pageSize || 10}&keyword=${params.keyword || ''}`),
  // 获取单个用户会员交流信息
  getAllMemberExchangeInfoForUser: (params = {}) => get(`/user/member-info-exchanges?currentPage=${params.currentPage || 1}&pageSize=${params.pageSize || 10}`),
  // 删除会员交流信息
  delMemberExchangeInfo: (params = {}) => del(`/member-info-exchanges/${params.id}`),
  // 获取单个会员交流信息
  getAllMemberExchangeInfoByID: (params = {}) => get(`/member-info-exchanges/${params.id}`),
  // 获取留言
  getAllCommentByInfoExchangeID: (params = {}) => get(`/member-info-exchanges/${params.id}/comments?currentPage=${params.currentPage || 1}&pageSize=${params.pageSize || 10}`),
  // 添加留言
  addComment: (params = {}) => post(`/member-info-exchanges/${params.id}/comments`, params),
  // 获取单个用户收到的所有留言
  getAllCommentForUser: (params = {}) => get(`/user/comments?currentPage=${params.currentPage || 1}&pageSize=${params.pageSize || 10}`),
  // 删除留言
  delComment: (params = {}) => del(`/comments/${params.id}`),

  // 添加入会申请
  addMemberApplication: (params = {}) => post('/member-applications', params),
  // 获取入会申请情况
  getMemberApplication: () => get('/user/member-applications'),
  // 获取所有会员
  getAllMember: (params = {}) => get(`/members?currentPage=${params.currentPage || 1}&pageSize=${params.pageSize || 10}&keyword=${params.keyword || ''}`),
  // 获取单个会员信息
  getMemberByID: (params = {}) => get(`/members/${params.id}`),

  // 获取当前用户信息
  getUserInfo: () => get('/user/info'),
  // 更新用户信息
  updateUserInfo: (params = {}) => put('/user/info', params),
  // 获取当前用户的会员信息
  getMember: () => get('/user/members'),
  // 更新会员信息
  updateMemberInfo: (params = {}) => put('/member/info', params),

  // 获取单个资讯
  getInfo: (params = {}) => get(`/info/${params.id}`),
  // 获取所有资讯
  getAllInfo: (params = {}) => get(`/info?filter[category]=${params.category || 'all'}&currentPage=${params.currentPage || 1}&pageSize=${params.pageSize || 10}&keyword=${params.keyword || ''}`),
  // 添加喜欢
  addFavour: (params = {}) => post(`/info/${params.id}/favour`),
  // 取消喜欢
  delFavour: (params = {}) => del(`/info/${params.id}/favour`),

  // 获取OSS Token
  getAliOSSToken: () => get('/ali-oss/token'),

  // 获取公共资源
  getAllResource: (params = {}) => get(`/resources?filter[category]=${params.category || 'all'}&currentPage=${params.currentPage || 1}&pageSize=${params.pageSize || 10}&keyword=${params.keyword || ''}`),
  // 通过邮箱下载公共资源
  getResourceByIDViaEmail: (params = {}) => get(`/resources/${params.id}/email`)
}

// Vue.use
const install = (Vue) => {
  if (install.installed) return
  install.installed = true

  // 定义属性到Vue原型中，这样每一个组件就可以通过this.$api.xxx(data) 去发送请求
  // Vue.prototype.$api = api
  Object.defineProperties(Vue.prototype, {
    $api: {
      get () {
        return api
      }
    },
    api: {
      get () {
        return api
      }
    }
  })
}

const getAccessToken = api.getAccessToken
const getUserInfo = api.getUserInfo

export {
  install as default,
  getAccessToken,
  getUserInfo
}
