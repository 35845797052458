import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Home from '../pages/Home.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Home', component: Home, meta: { title: '会员之家' } },
  { path: '/temp', name: 'Temp', component: () => import('@/pages/Temp.vue'), meta: { title: '临时导航页' } },
  { path: '/login', name: 'Login', component: () => import('@/pages/login/Login.vue'), meta: { title: '登录' } },
  { path: '/member-join', name: 'MemberJoin', component: () => import('@/pages/member/MemberJoin.vue'), meta: { title: '入会申请' } },
  { path: '/member-detail', name: 'MemberDetail', component: () => import('@/pages/member/MemberDetail'), meta: { title: '会员详情' } },
  { path: '/member-list', name: 'MemberList', component: () => import('@/pages/member/MemberList'), meta: { title: '会员风采', keepAlive: true, permission: ['member', 'manager'] } },
  { path: '/member-exchange-info-list', name: 'ExchangeInfoList', component: () => import('@/pages/member/ExchangeInfoList'), meta: { title: '会员信息交流', keepAlive: true, permission: ['member', 'manager'] } },
  { path: '/member-exchange-info-detail', name: 'ExchangeInfoDetail', component: () => import('@/pages/member/ExchangeInfoDetail'), meta: { title: '会员交流信息', permission: ['member', 'manager'] } },
  { path: '/add-member-exchange-info', name: 'AddExchangeInfo', component: () => import('@/pages/member/AddExchangeInfo'), meta: { title: '发布信息', permission: ['member', 'manager'] } },
  { path: '/member-news', name: 'MemberNews', component: () => import('@/pages/member/MemberNews'), meta: { title: '会员动态', keepAlive: true } },
  { path: '/member-news-detail', name: 'MemberNewsDetail', component: () => import('@/pages/member/MemberNewsDetail'), meta: { title: '会员动态详情' } },
  { path: '/industry-list', name: 'IndustryList', component: () => import('@/pages/industry/IndustryList'), meta: { title: '产业动态', keepAlive: true } },
  { path: '/industry-detail', name: 'IndustryDetail', component: () => import('@/pages/industry/IndustryDetail'), meta: { title: '产业动态详情' } },
  { path: '/policy-list', name: 'PolicyList', component: () => import('@/pages/policy/PolicyList'), meta: { title: '政策信息', keepAlive: true } },
  { path: '/policy-detail', name: 'PolicyDetail', component: () => import('@/pages/policy/PolicyDetail'), meta: { title: '政策信息详情' } },
  { path: '/association-list', name: 'AssociationList', component: () => import('@/pages/association/AssociationList'), meta: { title: '协会动态', keepAlive: true } },
  { path: '/association-detail', name: 'AssociationDetail', component: () => import('@/pages/association/AssociationDetail'), meta: { title: '协会动态详情' } },
  { path: '/activity-list', name: 'ActivityList', component: () => import('@/pages/activity/ActivityList'), meta: { title: '文创活动', keepAlive: true } },
  { path: '/activity-detail', name: 'ActivityDetail', component: () => import('@/pages/activity/ActivityDetail'), meta: { title: '文创活动详情' } },
  { path: '/activity-signup', name: 'ActivitySignup', component: () => import('@/pages/activity/ActivitySignup'), meta: { title: '活动报名' } },
  { path: '/activity-signin', name: 'ActivitySignin', component: () => import('@/pages/activity/ActivitySignin'), meta: { title: '活动签到' } },
  { path: '/creativity-list', name: 'CreativityList', component: () => import('@/pages/creativity/CreativityList'), meta: { title: '创意推荐', keepAlive: true } },
  { path: '/creativity-detail', name: 'CreativityDetail', component: () => import('@/pages/creativity/CreativityDetail'), meta: { title: '创意推荐详情' } },
  { path: '/personal-center', name: 'personalCenter', component: () => import('@/pages/person/PersonalCenter'), meta: { title: '个人中心' } },
  { path: '/user/info', name: 'UserInfo', component: () => import('@/pages/person/UserInfo'), meta: { title: '个人信息' } },
  { path: '/user/member', name: 'MemberInfo', component: () => import('@/pages/person/MemberInfo'), meta: { title: '会员信息', permission: ['member', 'manager'] } },
  { path: '/user/member-exchange-info-list', name: 'UserExchangeInfoList', component: () => import('@/pages/person/ExchangeInfoList'), meta: { title: '会员信息交流', keepAlive: true, permission: ['member', 'manager'] } },
  { path: '/user/certificate', name: 'Certificate', component: () => import('@/pages/person/Certificate'), meta: { title: '相关证书', permission: ['member', 'manager'] } },
  { path: '/user/activity-list', name: 'UserActivityList', component: () => import('@/pages/person/ActivityList'), meta: { title: '活动记录' } },
  { path: '/user/activity-detail', name: 'UserActivityDetail', component: () => import('@/pages/person/ActivityDetail'), meta: { title: '活动详情' } },
  { path: '/user/activity-evaluation', name: 'UserActivityEvaluation', component: () => import('@/pages/person/ActivityEvaluation'), meta: { title: '活动评价' } },
  { path: '/resource-list', name: 'ResourceList', component: () => import('@/pages/resource/ResourceList'), meta: { title: '下载中心', permission: ['member', 'manager'] } }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // 路由发生变化修改页面title
  if (to.meta.title) document.title = to.meta.title
  if (to.name === 'Login') return next()
  // 路由发生变化时压入当前path，用于底部导航栏
  store.commit('setCurrentPath', to.path)
  // 没有token就跳转登录
  if (!store.state.refreshToken) return router.push('/login').then()
  // 是否需要权限
  if (to.meta.permission && !to.meta.permission.includes(store.state.role)) return router.push('/member-join').then()
  next()
})

export default router
