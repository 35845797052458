import Vue from 'vue'
import Vuex from 'vuex'
import jwtDecode from 'jwt-decode'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    appID: 'wx68490c2579f35afc',
    baseURL: '',
    apiBaseURL: '',
    currentPath: '/',
    token: '',
    refreshToken: '',
    memberInfo: null,
    role: '',
    userInfo: null,
    loginCallback: []
  },

  getters: {},

  mutations: {
    // 设置回调函数
    addLoginCallBack (state, func) {
      state.loginCallback.push(func)
    },
    // 设置当前页面path
    setCurrentPath (state, currentPath) {
      state.currentPath = currentPath
      localStorage.setItem('currentPath', state.currentPath)
    },
    // 设置refresh token和access token
    setToken (state, token) {
      state.refreshToken = token.refreshToken
      state.token = token.accessToken
      localStorage.setItem('refresh-token', state.refreshToken)
      localStorage.setItem('token', state.token)
      // 加载role信息
      if (state.token) {
        const decode = jwtDecode(state.token)
        state.role = decode.role
      }
    },
    // 设置access token
    setAccessToken (state, accessToken) {
      state.token = accessToken
      localStorage.setItem('token', state.token)
      // 加载role信息
      if (state.token) {
        const decode = jwtDecode(state.token)
        state.role = decode.role
      }
    },
    // 设置会员信息
    setMemberInfo (state, memberInfo) {
      state.memberInfo = memberInfo
      localStorage.setItem('member-info', JSON.stringify(memberInfo))
    },
    // 设置用户信息
    setUserInfo (state, userInfo) {
      state.userInfo = userInfo
      localStorage.setItem('user-info', JSON.stringify(userInfo))
    },
    // 清楚所有缓存
    clearAll (state) {
      state.refreshToken = ''
      state.token = ''
      state.memberInfo = null
      state.userInfo = null
      state.role = ''
      localStorage.removeItem('refresh-token')
      localStorage.removeItem('token')
      localStorage.removeItem('member-info')
      localStorage.removeItem('user-info')
    },
    // 清楚access token
    clearAccessToken (state) {
      state.token = ''
      localStorage.removeItem('token')
    },
    // 加载所有缓存数据
    loadAll (state) {
      state.currentPath = localStorage.getItem('currentPath') || '/'
      // 加载url
      state.apiBaseURL = process.env.VUE_APP_API_BASE_URL
      state.baseURL = process.env.VUE_APP_BASE_URL
      // 加载token
      if (process.env.NODE_ENV === 'development') {
        localStorage.setItem('refresh-token', process.env.VUE_APP_REFRESH_TOKEN_MEMBER)
        localStorage.setItem('token', process.env.VUE_APP_TOKEN_MEMBER)
      }
      state.token = localStorage.getItem('token') || ''
      state.refreshToken = localStorage.getItem('refresh-token') || ''

      // 加载role信息
      if (state.token) {
        const decode = jwtDecode(state.token)
        state.role = decode.role
      }

      // 加载会员信息
      const memberInfo = localStorage.getItem('member-info')
      if (memberInfo && memberInfo !== 'undefined') {
        state.memberInfo = JSON.parse(memberInfo)
      }
      // 加载用户信息
      const userInfo = localStorage.getItem('user-info')
      if (userInfo && userInfo !== 'undefined') {
        state.userInfo = JSON.parse(userInfo)
      }
    }

  },

  actions: {},

  modules: {}
})
