<template>
  <div>
    <!-- 占位行 -->
    <div class="placeholder"></div>
    <!-- 底部导航栏 -->
    <div class="navigation-bar flex-row-evenly-center">
      <div :class="item.path === currentPath ? 'active item flex-column-center-center': 'item flex-column-center-center'" v-for="(item, index) in list" :key="index" @click="handleSwitchPage(item.path)">
        <span :class="'iconfont ' + item.icon"></span>
        <span class="label">{{item.label}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavigationBar',

  data () {
    return {
      list: [{
        icon: 'icon-member',
        label: '会员之家',
        isActive: true,
        path: '/'
      }, {
        icon: 'icon-user',
        label: '个人中心',
        isActive: false,
        path: '/personal-center'
      }]
    }
  },

  computed: {
    currentPath () {
      return this.$store.state.currentPath
    }
  },

  methods: {
    // 切换页面
    handleSwitchPage (path) {
      if (path === this.currentPath) return
      if (this.list.map(res => res.path).includes(this.currentPath)) {
        this.$router.replace(path)
      } else {
        this.$router.push(path)
      }
    }
  }
}
</script>

<style scoped>
.placeholder {
  height: 60px;
}
.navigation-bar {
  width: 100%;
  height: 60px;
  z-index: 9999;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #fff;
  border-top: 1px solid #eee;
}
.item {
  height: 100%;
  width: 80px;
}
.iconfont {
  font-size: 25px;
}
.label {
  font-size: 12px;
}
.active {
  color: var(--main-color);
}
</style>
