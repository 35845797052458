<template>
  <div>
    <!-- 头图 -->
    <img class="top-image" :src="require(`@/assets/images/member_header.png`)" />
    <!-- 列表 -->
    <div class="list">
      <!-- 列表项 -->
      <div class="list-item flex-column-center-center" v-for="(item, index) in list" :key="index" @click="handleSwitchPage(index)">
        <span :class="'iconfont ' + item.icon"></span>
        <span class="label">{{item.label}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',

  data () {
    return {
      list: [],
      userList: [
        { label: '入会申请', path: '/member-join', icon: 'icon-application' },
        { label: '会员风采', path: '/member-list', icon: 'icon-member' },
        { label: '信息交流', path: '/member-exchange-info-list', icon: 'icon-chat' },
        { label: '会员动态', path: '/member-news', icon: 'icon-news' },
        { label: '产业动态', path: '/industry-list', icon: 'icon-industry' },
        { label: '政策信息', path: '/policy-list', icon: 'icon-policy' },
        { label: '协会动态', path: '/association-list', icon: 'icon-association' },
        { label: '文创活动', path: '/activity-list', icon: 'icon-activity' },
        { label: '创意推荐', path: '/creativity-list', icon: 'icon-idea' },
        { label: '下载中心', path: '/resource-list', icon: 'icon-resource' }
      ],
      memberList: [
        { label: '会员风采', path: '/member-list', icon: 'icon-member' },
        { label: '信息交流', path: '/member-exchange-info-list', icon: 'icon-chat' },
        { label: '会员动态', path: '/member-news', icon: 'icon-news' },
        { label: '产业动态', path: '/industry-list', icon: 'icon-industry' },
        { label: '政策信息', path: '/policy-list', icon: 'icon-policy' },
        { label: '协会动态', path: '/association-list', icon: 'icon-association' },
        { label: '文创活动', path: '/activity-list', icon: 'icon-activity' },
        { label: '创意推荐', path: '/creativity-list', icon: 'icon-idea' },
        { label: '下载中心', path: '/resource-list', icon: 'icon-resource' }
      ]
    }
  },

  beforeCreate () {
    const images = [
      require('@/assets/images/member_header.png')
    ]
    for (const src of images) {
      const image = new Image()
      image.src = src
    }
  },

  created () {
    const userInfo = this.$store.state.userInfo
    if (userInfo && userInfo.isMember) {
      this.list = this.memberList
    } else {
      this.list = this.userList
    }
  },

  methods: {
    // 切换页面
    handleSwitchPage (index) {
      this.$router.push(this.list[index].path)
    }
  }
}
</script>

<style scoped>
.list {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: calc(3vw + 10px);
}
.list-item {
  width: calc((100vw - 3vw) / 3 - 3vw);
  height: calc((100vw - 3vw) / 3 - 3vw);
  margin: 3vw 0 0 3vw;
  background: var(--main-color);
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 6px #aaa;
}
.iconfont {
  font-size: 10vw;
}
.label {
  font-size: 3.5vw;
}
</style>
