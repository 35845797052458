import axios from 'axios'
import store from '../store'
import router from '@/router'
import { Toast } from 'mint-ui'
import { getAccessToken, getUserInfo } from './api'

// 不放这里BaseURL不及时生效
store.commit('loadAll')

// axios配置
const config = {
  baseURL: store.state.apiBaseURL,
  headers: {
    post: {
      'Content-Type': 'application/json;charset=utf-8'
    }
  }
}

const _axios = axios.create(config)

// 请求拦截器
_axios.interceptors.request.use(config => {
  const token = store.state.token
  token && (config.headers.Authorization = token)
  return config
// eslint-disable-next-line handle-callback-err
}, err => {
  console.error(err)
  const errMsg = '无法连接到服务器'
  Toast(errMsg)
  return Promise.reject(errMsg)
})

// 响应拦截器
_axios.interceptors.response.use((response) => {
  // 只返回response中的data数据
  return response.data
}, function (error) {
  if (error.response) {
    error.response.data = error.response.data || {}
    switch (error.response.status) {
      case 400: {
        const errMsg = error.response.data.msg || '参数错误'
        Toast(errMsg)
        return Promise.reject(error.response)
      }
      case 401: {
        store.commit('clearAccessToken')
        // 静默获取access token
        if (store.state.refreshToken) {
          getAccessToken({ refreshToken: store.state.refreshToken }).then(res => {
            store.commit('setAccessToken', res.data.accessToken)
            getUserInfo().then(res => {
              store.commit('setUserInfo', res.userInfo)
            })
            // router.go(0)
            if (store.state.loginCallback.length > 0) {
              for (let index = store.state.loginCallback.length - 1; index >= 0; index--) {
                store.state.loginCallback[index]()
                store.state.loginCallback.splice(index, 1)
              }
            }
          }).catch(() => {
            const errMsg = error.response.data.msg || '未登录'
            Toast(errMsg)
            store.commit('clearAll')
            router.push('/login').then()
          })
        } else {
          router.push('/login').then()
        }
        return Promise.reject(error.response)
      }
      case 403: {
        const errMsg = error.response.data.msg || '无权限'
        Toast(errMsg)
        return Promise.reject(error.response)
      }
      case 404: {
        const errMsg = error.response.data.msg || '页面不存在'
        Toast(errMsg)
        return Promise.reject(error.response)
      }
      case 500: {
        const errMsg = error.response.data.msg || '服务出错，请稍后重试或者联系管理员'
        Toast(errMsg)
        return Promise.reject(error.response)
      }
      case 502: {
        const errMsg = error.response.data.msg || '无法连接到服务器'
        Toast(errMsg)
        return Promise.reject(errMsg)
      }
    }
    const errMsg = '出现未知错误，请稍后重试或者联系管理员'
    Toast(errMsg)
    return Promise.reject(errMsg)
  } else {
    const errMsg = '无法连接到服务器'
    Toast(errMsg)
    return Promise.reject(errMsg)
  }
})

// 封装请求
export const get = (url) => _axios.get(url)
export const post = (url, params = {}) => _axios.post(url, params)
export const put = (url, params = {}) => _axios.put(url, params)
export const del = (url) => _axios.delete(url)
